import Swiper, { EffectFade, Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination, EffectFade]);

/**
 * ImageGridSlider
 * One main text-slider
 * Three image slider that listen to the main slidesr progress
 */
class ImageGridSlider {
  /**
   * constructor for ImageGriSlider
   *
   * @param   {HTMLElement}  wrapperElement Outer Element of Component
   *
   * @return  {ClassInstance}
   */
  constructor(wrapperElement) {
    this.s = {
      sliderText: ".js-igs__slider--text",
      sliderImage: ".js-igs__slider--image",
      pagination: ".js-igs__pagination",
      prev: ".js-igs__prev",
      next: ".js-igs__next",
      navigation: ".js-igs__navigation",
    };
    this.e = {
      wrapper: wrapperElement,
      sliderText: wrapperElement.querySelector(this.s.sliderText),
      slidersImage: wrapperElement.querySelectorAll(this.s.sliderImage),
      pagination: wrapperElement.querySelector(this.s.pagination),
      prev: wrapperElement.querySelector(this.s.prev),
      next: wrapperElement.querySelector(this.s.next),
      navigation: wrapperElement.querySelector(this.s.navigation),
    };

    this.options = {
      navigationVisibleClass: "igs__navigation--visible",
    };

    this.swiperText;
    this.swiperImages = [];

    if (this.e.wrapper && this.e.sliderText) {
      this.init();
    }

    return this;
  }

  init() {
    this.initImageSliders();
    this.initSwiperText();
  }

  /**
   * Init the swiper instance
   */
  initSwiperText() {
    this.swiperText = new Swiper(this.e.sliderText, {
      slidesPerView: 1,
      spaceBetween: 24,
      speed: 500,
      observer: true,
      observeParents: true,
      pagination: {
        clickable: false,
        el: this.e.pagination,
        type: "fraction",
      },
      navigation: {
        nextEl: this.e.next,
        prevEl: this.e.prev,
      },
      on: {
        afterInit: (swiperInstance) => {
          // add hidden navigation element
          if (!this.e.navigation) {
            return;
          }
          if (swiperInstance.slides.length >= 2) {
            this.e.navigation.classList.add(this.options.navigationVisibleClass);
          } else {
            this.e.navigation.classList.remove(this.options.navigationVisibleClass);
          }
        },
        slideChange: (swiperInstance) => {
          this.swiperImages.forEach((imageSlider) => {
            imageSlider.slideTo(swiperInstance.activeIndex);
          });
        },
      },
    });
  }

  /**
   * Init other image sliders
   * They are all controlled by the swiper instance of the text-slider
   */
  initImageSliders() {
    this.e.slidersImage.forEach((slider) => {
      const imageSlider = new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 24,
        effect: "fade",
        allowTouchMove: false,
        fadeEffect: {
          crossFade: true,
        },
      });
      this.swiperImages.push(imageSlider);
    });
  }
}

export default () => {
  const sliderElements = document.querySelectorAll(".js-igs");
  sliderElements.forEach(function (element) {
    new ImageGridSlider(element);
  });
};
