//
// Get CSS Breakpoints Value
//
const breakpoint = {};

breakpoint.refreshValue = function () {
  this.value = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
};

window.addEventListener("resize", function () {
  breakpoint.refreshValue();
});
breakpoint.refreshValue();

window.breakpoint = breakpoint;
