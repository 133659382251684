import {
  $,
  $All,
} from "Packages/Bahn/DB.Neos.Base/Resources/Private/Assets/Scripts/Modules/Helper";
//
// Map Overlay Marker Hover
//
const $imageHiglighterText = $All(".js-image-highlighter__text");
const $imageHiglighterMarker = $All(".js-image-highlighter__marker");
const activeClass = "active";
const hoverClass = "hover";
const modalHash = `modal-${location.hash.replace(/^#/, "")}`;

function closeModal() {
  location.hash = "close-modal";
  const activeModal = $(`.image-highlighter__modal.${activeClass}`);
  if (activeModal) {
    activeModal.classList.remove(activeClass);
    document.body.classList.remove("locked");
  }
}

function addModalHandler(element, modalId) {
  element.addEventListener("click", function (event) {
    closeModal(); // Close modals if there is one open
    const modal = document.getElementById(modalId);
    if (!modal) return; // Make sure, modal exists
    modal.classList.add(activeClass);
    document.body.classList.add("locked");
  });
}

$imageHiglighterText.forEach(function (el, i) {
  const hoverImage = $(`#${el.dataset.overlay}`);
  const modalId = el.dataset.modal;

  el.addEventListener("mouseenter", function () {
    $imageHiglighterMarker[i].classList.add(hoverClass);

    if (hoverImage !== null) {
      hoverImage.classList.add(activeClass);
    }
  });

  el.addEventListener("mouseleave", function () {
    $imageHiglighterMarker[i].classList.remove(hoverClass);

    if (hoverImage !== null) {
      hoverImage.classList.remove(activeClass);
    }
  });

  if (modalId) {
    addModalHandler(el, modalId);

    // If the current hash matches the modal id, open the modal
    if (modalHash === modalId) {
      el.click();
    }
  }
});

$imageHiglighterMarker.forEach(function (el, i) {
  const hoverImage = $(`#${el.dataset.overlay}`);
  const modalId = el.dataset.modal;

  el.addEventListener("mouseenter", function () {
    $imageHiglighterText[i].classList.add(hoverClass);

    if (hoverImage !== null) {
      hoverImage.classList.add(activeClass);
    }
  });

  el.addEventListener("mouseleave", function () {
    $imageHiglighterText[i].classList.remove(hoverClass);

    if (hoverImage !== null) {
      hoverImage.classList.remove(activeClass);
    }
  });

  if (modalId) {
    addModalHandler(el, modalId);
  }
});

document.addEventListener(
  "click",
  function (event) {
    const button = event.target.closest(".image-highlighter__modal-close");
    if (button) {
      closeModal();
    }
  },
  false
);

document.addEventListener("keydown", function (event) {
  if (event.key === "Escape") {
    closeModal();
  }
});
