function $(selector, context) {
	return (context || document).querySelector(selector);
}

function $All(selector, context) {
	const arr = [];
	arr.push.apply(arr, (context || document).querySelectorAll(selector));
	return arr;
}

const mainMenu = $(".js-menu");
const mainMenuToggleLinks = $All(".js-toggle-menu__item > .js-disabled-link");
const mainMenuDisabledLinks = $All(".js-disabled-link");

const menuItemClass = "menu__item";
const openMenuItemClass = `${menuItemClass}--is-open`;
//
// Open Desktop Overlay Menu Items
//
mainMenuToggleLinks.forEach(function (el) {
	el.addEventListener("click", function (event) {
		event.preventDefault();

		if (el.parentNode.classList.contains(openMenuItemClass)) {
			el.parentNode.children[0].setAttribute("aria-expanded", "false");
			el.parentNode.classList.remove(openMenuItemClass);
			return;
		}

		// close all other open Menus
		mainMenuToggleLinks.forEach(function (openElements) {
			openElements.parentNode.classList.remove(openMenuItemClass);
			openElements.setAttribute("aria-expanded", "false");
		});

		el.parentNode.classList.add(openMenuItemClass);
		el.parentNode.children[0].setAttribute("aria-expanded", "true");
	});
});

//
// Detect Click outside of Overlay Menu
//
document.addEventListener("click", function (event) {
	if (event.target.closest(".js-toggle-menu__item, .submenu")) return;

	const openItem = $(`.js-toggle-menu__item.${openMenuItemClass}`);
	if (openItem !== null) {
		openItem.classList.remove(openMenuItemClass);
		openItem.children[0].setAttribute("aria-expanded", "false");
	}
});

//
// Detect ESC Key to close Overlay Menu
//
document.addEventListener("keyup", function (event) {
	if (event.code === "Escape" || event.key === "Escape") {
		const openItem = $(`.js-toggle-menu__item.${openMenuItemClass}`);
		if (openItem !== null) {
			openItem.classList.remove(openMenuItemClass);
			openItem.querySelector("a").focus({ focusVisible: true });
			openItem.children[0].setAttribute("aria-expanded", "false");
		}
	}
});

document.addEventListener("focusout", function (event) {
	// Check if the focusout event is originating from within any submenu
	const submenu = event.target.closest(".submenu");
	if (submenu) {
		let openItem = $(`.js-toggle-menu__item.${openMenuItemClass}`);
		setTimeout(() => {
			if (!submenu.contains(document.activeElement)) {
				openItem.classList.remove(openMenuItemClass);
				openItem.children[0].setAttribute("aria-expanded", "false");
			}
		}, 0);
	}
});

//
// Open Desktop Overlay Menu Items
//
mainMenuDisabledLinks.forEach(function (link) {
	link.addEventListener("click", function (event) {
		event.preventDefault();
	});
});

// #################### Hero Header & Header Basic ####################

const headerMain = document.querySelector(".header-hero .header-hero__main");
const mobileOpenClass = "menu--open";
const mobileMenu = document.querySelector(".js-menu");
const preventScrollingClass = "overflow-hidden";
const mobileMenuToggle = document.querySelector(".js-showmenu__toggle");
const body = document.querySelector("body");
const headerBasicLogo = document.querySelector(".header-basic__logo");
const headerHeroLogo = document.querySelector(".header-hero__logo");

if (mobileMenuToggle != null) {
	mobileMenuToggle.addEventListener("click", () => {
		mainMenu.classList.toggle(mobileOpenClass);
		headerMain?.classList.toggle("js-showmenu--open");
		body.classList.toggle(preventScrollingClass);
		mobileMenuToggle.setAttribute("aria-expanded", mainMenu.classList.contains(mobileOpenClass).toString());
	});
}

//
// Close Mobile Menu on window.resize
//
window.addEventListener("resize", function () {
	if (mainMenu.classList.contains(mobileOpenClass)) {
		mainMenu.classList.remove(mobileOpenClass);
		body.classList.remove(preventScrollingClass);
	}
});

// Add focus trap for mobile menu
const lastMenuItem = mobileMenu.querySelector("& > li:last-of-type > a");
if (lastMenuItem) {
	lastMenuItem.addEventListener("focusout", function () {
		headerBasicLogo && headerBasicLogo.focus();
		headerHeroLogo && headerHeroLogo.focus();
	});
}

[
	...document.querySelectorAll(
		".header-hero a:not(.header-hero__topbar-link--lang), .header-basic a:not(.js-disabled-link"
	),
]
	// maybe we should exclude the submenu links for links in the sidebar
	.filter((link) => !link.closest(".submenu__aside"))
	.filter((link) => link.href === location.href.replace(/#$/, ""))
	.forEach((link) => {
		link.classList.add("active");
		link.closest(".menu__item")?.firstChild?.classList.add("active");
	});
