document.querySelectorAll(".downloadlist").forEach((downloadlist) => {
  const toggleButtons = downloadlist.querySelectorAll(".plus-minus-toggle span");
  const checkbox = downloadlist.querySelector(".show-additional-entries");

  toggleButtons.forEach((button) => {
    button.addEventListener("keydown", (event) => {
      if (button.style.display === "none") return;
      if (event.key === "Enter") {
        checkbox.checked = !checkbox.checked;
        const visibleButton = checkbox.checked ? toggleButtons[1] : toggleButtons[0];
        visibleButton.focus();
      }
    });
  });
});
