function $(selector, context) {
    return (context || document).querySelector(selector);
}

function $All(selector, context) {
    var arr = [];
    arr.push.apply(arr, (context || document).querySelectorAll(selector));
    return arr;
}

//
// To-Top Anchor Link
//
let toTopLink = document.querySelector(".js-totop");

if (toTopLink) {
	toTopLink.href = `${location.href}#top`;
	let pixeltowatch = $(".pixeltowatch");

	if ("IntersectionObserver" in window) {
		let config = {
			root: null,
			rootMargin: "0px",
			threshold: 0,
		};

		let observer = new IntersectionObserver(onChange, config);
		observer.observe(pixeltowatch);

		function onChange(changes, observer) {
			changes.forEach((change) => {
				if (change.isIntersecting) {
					toTopLink.classList.add("is--visible");
				} else {
					if (change.boundingClientRect.top > 0) {
						toTopLink.classList.remove("is--visible");
					}
				}
			});
		}
	} else {
		console.log("No Intersection Observer Support");
		toTopLink.classList.add("intersectionObserverNotSupported");
	}
}