//
// Selector Helper
//

export function $(selector, context) {
  return (context || document).querySelector(selector);
}

export function $All(selector, context) {
  const arr = [];
  arr.push.apply(arr, (context || document).querySelectorAll(selector));
  return arr;
}

//
// Width Helper
//

//export function getWidth(element) {
window.getWidth = function getWidth(element) {
  if (typeof element == "string") {
    element = $(element);
  }

  var width = element.clientWidth;
  var style = getComputedStyle(element);
  width += parseInt(style.marginLeft);
  width += parseInt(style.marginRight);
  width += parseInt(style.borderLeftWidth) || 0;
  width += parseInt(style.borderRightWidth) || 0;
  return width;
};
