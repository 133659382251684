// first, add all tags of following elements to
// data-tags attribute of each accordion headline

document.querySelectorAll(".accordion__headline").forEach((element) => {
  let nextElement = element.nextSibling;
  let headlineTags = [];
  while (nextElement != null && nextElement.classList.contains("accordion")) {
    const tags = nextElement.dataset.tags?.split(",");
    tags.forEach((tag) => {
      if (!headlineTags.includes(tag) && tag != "") {
        headlineTags.push(tag);
      }
    });
    nextElement = nextElement.nextSibling;
  }
  element.dataset.tags = headlineTags.join(",");
});

// ...then, the filter will work for the headlines as well.

const updateTagFilter = (element) => {
  const items = element.closest("section").querySelectorAll(".accordion__filterable");
  const filterForTag = element.value;

  items.forEach((item) => {
    const tags = item.dataset.tags?.split(",");
    item.classList.remove("accordion--hidden-by-tag");
    if (!tags.includes(filterForTag) && filterForTag != "") {
      item.classList.add("accordion--hidden-by-tag");
    }
  });
};

document.querySelectorAll("select.accordion__filter__tag").forEach((element) => {
  // change "tag" filter
  element.addEventListener("change", (e) => {
    updateTagFilter(element);
  });
});
