//Helper imports
import { $All } from "Packages/Bahn/DB.Neos.Base/Resources/Private/Assets/Scripts/Modules/Helper";
/* import { getWidth } from "./Helper"; */

import Alpine from "alpinejs";

window.alpineHelper = {
  swipeEvents: (element, options = {}) => {
    const minDistance = options.minDistance || 100;
    const maxTime = options.maxTime || 500;

    let didDown;
    let didSwipe;
    let startTime;
    let startPos;

    if (!options.touch && !options.mouse) {
      return;
    }
    const down = (event, isTouch) => {
      event.preventDefault();
      didDown = true;
      startTime = Date.now();
      startPos = position(event, isTouch);
    };

    const move = (event, isTouch) => {
      event.preventDefault();
      if (!didDown) {
        return;
      }
      didSwipe = true;
      const offsets = getOffsets(event, startPos, isTouch);
      const directions = getDirections(offsets);
      const direction = order(directions)[0];
      const distance = directions[direction];

      element.dispatchEvent(
        new CustomEvent(`swipe-${direction}`, {
          bubbles: true,
          cancelable: true,
          detail: {
            direction,
            distance,
            offsets,
          },
        })
      );
    };

    const up = (event, isTouch) => {
      event.preventDefault();
      didDown = false;
      if (!didSwipe) {
        return;
      }
      didSwipe = false;

      const elapsedTime = Date.now() - startTime;
      if (elapsedTime <= maxTime) {
        const offsets = getOffsets(event, startPos, isTouch);
        const directions = getDirections(offsets);
        const direction = order(directions)[0];
        const distance = directions[direction];

        if (distance >= minDistance) {
          element.dispatchEvent(
            new CustomEvent(`swiped-${direction}`, {
              bubbles: true,
              cancelable: true,
              detail: {
                direction,
                distance,
                offsets,
                elapsedTime,
              },
            })
          );
        }
      }
    };

    // Add event listeners
    if (options.touch) {
      element.addEventListener("touchstart", (event) => down(event, true));
      element.addEventListener("touchmove", (event) => move(event, true));
      element.addEventListener("touchend", (event) => up(event, true));
    }
    if (options.mouse) {
      element.addEventListener("mousedown", (event) => down(event, false));
      document.addEventListener("mousemove", (event) => move(event, false));
      document.addEventListener("mouseup", (event) => up(event, false));
    }
  },
  /** Video and links handling */
  elementsInSlideHandling: (element, visible, index, mediaContainer) => {
    /* const $All = window.$All; */
    const lottie = window.lottie;
    mediaContainer = mediaContainer || element;
    index = index || 0;

    $All("button, a, video", element).forEach((item) => {
      if (visible) {
        item.removeAttribute("tabindex");
      } else {
        item.setAttribute("tabindex", "-1");
      }
    });

    if (visible) {
      const lottieContainers = $All(".js-lottie", mediaContainer);
      const lottieContainer = lottieContainers[index] || lottieContainers[0];

      if (lottieContainer) {
        if (lottieContainer.lottie) {
          lottieContainer.lottie.stop();
          lottieContainer.lottie.play();
        } else {
          lottieContainer.lottie = lottie.loadAnimation({
            container: lottieContainer,
            renderer: "svg",
            loop: lottieContainer.getAttribute("data-loop") === "",
            autoplay: true,
            path: lottieContainer.getAttribute("data-lottie"),
          });
        }
      }

      const jsVideo = mediaContainer.querySelector(".js-video");
      const videoCover = mediaContainer.querySelector(".video__cover");
      const videoButton = mediaContainer.querySelector(".js-videobuttonwrapper");
      if (jsVideo != null) {
        const video = jsVideo.querySelector("video");

        if (video != null) {
          if (videoCover) {
            videoCover.remove();
          }
          if (videoButton) {
            videoButton.remove();
          }
          video.setAttribute("loop", true);
          video.play();
        }
      }
    } else {
      $All("video", element).forEach((video) => {
        video.pause();
      });
    }
  },
};

function position(event, isTouch) {
  const obj = isTouch ? event.changedTouches[0] : event;
  return { x: obj.pageX, y: obj.pageY };
}

function getOffsets(event, startPos, isTouch) {
  const newPos = position(event, isTouch);
  return {
    x: newPos.x - startPos.x,
    y: newPos.y - startPos.y,
  };
}
function getDirections(offsets) {
  return {
    left: offsets.x <= 0 ? Math.abs(offsets.x) : 0,
    right: offsets.x >= 0 ? Math.abs(offsets.x) : 0,
    up: offsets.y <= 0 ? Math.abs(offsets.y) : 0,
    down: offsets.y >= 0 ? Math.abs(offsets.y) : 0,
  };
}

function order(directions) {
  return Object.keys(directions).sort((a, b) => directions[b] - directions[a]);
}

window.Alpine = Alpine;
Alpine.start();
