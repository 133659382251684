// IE detection
// returns version of IE or false, if browser is not Internet Explorer
//

function detectIE() {
  function detect() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");

    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    var edge = ua.indexOf("Edge/");

    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    } // other browser

    return false;
  }

  if (detect() != false) {
    if (detect() == 16) {
      document.documentElement.classList.add("msie edge-16");
    } else if (detect() >= 17) {
      document.documentElement.classList.add("no-msie edge-17+");
    } else {
      document.documentElement.classList.add("msie");
    }
  } else {
    document.documentElement.classList.add("no-msie");
  } //
  // Remove .no-js class from html element if javascript is enabled
  //
}

detectIE();
