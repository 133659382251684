if (document.querySelector('.js-video .js-video-poster')) {
  const videos = document.querySelectorAll('.js-video');

  videos.forEach((video) => {
    const videoEl = video.querySelector('video');
    videoEl.style.display = 'none';
    const videoPoster = video.querySelector('.js-video-poster');
    const videoPlayButton = video.querySelector('.js-video-poster .btn');

    videoPlayButton.addEventListener('click', () => {
      playVideo();
    });

    // Add keyboard support
    videoPlayButton.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        playVideo();
      }
    });

    const playVideo = () => {
      videoPoster.classList.add('hidden');
      videoEl.style.display = 'block';
      videoEl.play();
      videoEl.focus();
    };
  });
}
